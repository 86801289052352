<template>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="机会属性设置" :name="1">
            机会属性设置
        </el-tab-pane>
        <el-tab-pane label="机会关键属性设置" :name="2">
            机会关键属性设置
        </el-tab-pane>
        <el-tab-pane label="机会池页面设置" :name="3">
            机会池页面设置
        </el-tab-pane>
        <el-tab-pane label="我的机会页面设置" :name="4">
            我的机会页面设置
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import CluePage from "./component/CluePage.vue";

export default {
    name: "全部机会配置",
    components: {CluePage},
    data() {
        return {
            activeName: 1
        };
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
}
</script>

<style scoped>

</style>